import ApiService from '@/core/services/api.service';
import JwtService from '@/core/services/jwt.service';
import jwt from 'jwt-decode';

// action types
export const VERIFY_AUTH = 'verifyAuth';
export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const UPDATE_MY_USER = 'updateUser';
export const GET_MY_USER = 'getMyUser';

// mutation types
export const PURGE_AUTH = 'logOut';
export const SET_AUTH = 'setUser';
export const SET_ERROR = 'setError';
export const SET_USER = 'setMyUser';

const state = {
  errors: null,
  user: {},
  myUser: {},
  registeredUser: {},
  isAuthenticated: false,
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  myUser(state) {
    return state.myUser;
  },
  isAuthenticated(state) {
    return state.user && state.user.t != '';
    //return state.isAuthenticated;
  },
};

const actions = {
  [LOGIN](context, payload) {
    context.commit(SET_AUTH, payload);
  },
  [LOGOUT](context) {
    JwtService.destroySelectedCompanyId();
    context.commit(PURGE_AUTH);
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken() && JwtService.getCurrentUser()) {
      ApiService.setHeader();
      // context.commit(SET_AUTH, JwtService.getCurrentUser());
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_MY_USER](context, payload) {
    context.commit(SET_AUTH, payload);
    let user = jwt(payload);
    state.user = user;
  },
  [GET_MY_USER](context,payload) {
    // return new Promise((resolve, reject) => {
    //   // TODO : Kaldırılacak
    //   // let data = {
    //   //   data: {
    //   //     id: '2c2b3dfa-9d2c-4574-a378-6b54bc419b5f',
    //   //     token:
    //   //       'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpIjoiNjA5YmRjZjAtM2IyZS00MDlkLWFlMTktMzQzY2I4NjE1NThhIiwidCI6IlN5c3RlbUFkbWluaXN0cmF0b3IiLCJlIjoiaWxldGlzaW1AYnVyYWtrYXNpa2NpLmNvbSIsImYiOiJCdXJhayBLYcWfxLFrY8SxIiwiYyI6IiIsIm8iOiIiLCJuYmYiOjE2ODczNzUwNTUsImV4cCI6MTY4NzYzNDI1NSwiaWF0IjoxNjg3Mzc1MDU1fQ.JMZU_OrAcqXIPuaWXR1SvIsvjOTK0Hs7nsyBVSLhhi4',
    //   //     fullName: 'Test User',
    //   //     email: 'iletisim@burakkasikci.com',
    //   //     userType: 'SystemAdministrator',
    //   //   },
    //   //   page: 0,
    //   //   count: 0,
    //   //   limit: 0,
    //   //   pageCount: 0,
    //   //   errors: null,
    //   //   success: true,
    //   //   error: false,
    //   //   statusCode: 0,
    //   // };
    context.commit(SET_USER, payload);
    // });
    // return new Promise((resolve, reject) => {
    //   ApiService.get('account')
    //     .then(({ data }) => {
    //       context.commit(SET_USER, data.data.token);
    //       resolve(data);
    //     })
    //     .catch((error) => {
    //       //catch the error here
    //       // console.log(error)
    //       reject(error);
    //     });
    // });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, payload) {
    let user = jwt(payload);
    let myUser =payload;
    const isExpired = !user || user?.exp < Date.now() / 1000;
    state.isAuthenticated = !isExpired;
    state.user = user;
    state.myUser = myUser
    state.errors = {};
    JwtService.saveToken(payload);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
  [SET_USER](state, payload) {
    // state.user.credit_Total = payload.Credit_Total;
    // state.user.credit_Used = payload.Credit_Used;
    // state.user.emailAddress = payload.EmailAddress;
    // state.user.fullName = payload.Name + " " + payload.SurName;
    // state.user.id = payload.Id;
    // state.user.userType = payload.UserType;
    // state.user.country = payload.Country;

    // let currentUser = JwtService.getCurrentUser();
    // currentUser.user = state.user;
    
    state.myUser = payload;
    let user = jwt(payload);
    state.user = user;
    JwtService.saveToken(payload);
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
import axios from "axios";

// action types
export const COUNTIES = "counties";
export const DISTRICTS = "districts";
export const DISTRICT = "district";
export const COUNTY = "county";

export const NEIGHBOURHOODS = "neighbourhood";

// mutation types

export const SET_COUNTIES = "setCounties";
export const SET_DISTRICTS = "setDistricts";
export const SET_NEIGHBOURHOODS = "setNeighbourhood";

const state = {
  counties: [],
  districts: [],
  neighbourhoods: [],
};

const getters = {
  getCounties(state, context) {
    return state.counties;
  },
  getDistricts(state) {
    return state.districts;
  },
  getNeighbourhood(state) {
    return state.neighbourhoods;
  },
};

const actions = {
  async [COUNTIES](context) {
    try {
      const response = await axios.get(
        process.env.VUE_APP_LOCATION_URL + "iller"
      );
      context.commit(SET_COUNTIES, response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching counties:", error);
      throw error; // You may choose to handle the error in a different way based on your application's needs.
    }
  },
  async [COUNTY](context, payload) {
    try {
      const response = await axios.get(
        process.env.VUE_APP_LOCATION_URL + "il?il_id=" + payload
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching counties:", error);
      throw error; // You may choose to handle the error in a different way based on your application's needs.
    }
  },
  async [DISTRICTS](context, payload) {
    return await axios
      .get(process.env.VUE_APP_LOCATION_URL + "ilceler?il_id=" + payload)
      .then((response) => {
        context.commit(SET_DISTRICTS, response.data);
        return response.data;
      })

      .catch((error) => {
        console.error("Error fetching districts:", error);
        return [];
      });
  },
  async [NEIGHBOURHOODS](context, payload) {
    return await axios
      .get(
        process.env.VUE_APP_LOCATION_URL +
          "mahalleler?il_id=" +
          payload.il_id +
          "&ilce_id=" +
          payload.ilce_id
      )
      .then((response) => {
        context.commit(SET_NEIGHBOURHOODS, response.data);
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching districts:", error);
        return [];
      });
  },
  async [DISTRICT](context, payload) {
    try {
      const response = await axios.get(
        process.env.VUE_APP_LOCATION_URL + "ilce?ilce_id=" + payload
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching districts:", error);
      return [];
    }
  },
};
const mutations = {
  [SET_COUNTIES](state, payload) {
    state.counties = payload;
  },
  [SET_DISTRICTS](state, payload) {
    state.districts = payload;
  },
  [SET_NEIGHBOURHOODS](state, payload) {
    state.neighbourhoods = payload;
  },
};
export default {
  state,
  actions,
  mutations,
  getters,
};

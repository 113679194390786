import ApiService from '@/core/services/api.service';

//action types
export const PROPERTY = "property"

// mutatiton types
export const SET_PROPERTY = "setProperty"



const state={
    property : {}
}

const getters = {
    getProperty(state){
        return state.property
    }
}

const actions = {
    async [PROPERTY](context,payload){
        try {
            let query = `mulkler_list?Page=1&limit=1&token=${payload.token}&searchValue=${payload.id}&column=_id`;
            const response = await ApiService.get(query)
            context.commit(SET_PROPERTY,response.data.result[0])
            return response.data[0]
        } catch (error) {
            console.error("Error fetching sellers:", err);
            return [];
        }
    }
}

const mutations = {
    [SET_PROPERTY](state,payload){
        state.property = payload
    }
}

export default {
    state,
    actions,
    mutations,
    getters,
  };
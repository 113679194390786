import Vue from "vue";
import Router from "vue-router";
import store from "@/core/services/store";

//for NavigationDuplicated error.
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(Router);

// Import middleware
import verifyRoute from "@/middleware/verifyRoute";
import verifyUser from "@/middleware/verifyUser";

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/",
          name: "anasayfa",
          meta: {
            title: "Ana Sayfa | DveB Panel",
            middleware: [verifyUser],
          },
          component: () => import("@/view/pages/Dashboard.vue"),
        },
        {
          path: "/mulkler",
          name: "mulkler",
          meta: {
            title: "Mülkler | DveB Panel",
            middleware: [verifyUser],
          },
          component: () => import("@/view/layout/SubLayout"),
          children:[
            {
              path: "/",
              name: "mulkler",
              meta: {
                title: "Mülkler | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/view/pages/Properties.vue"),
            },
            {
              path: "mulk-detay/:id/",
              name: "mulk-detay",
              meta: {
                title: "Mülk Detayı | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/components/properties/DetailLayout.vue"),
              children:[
                {
                  path:"odemeler",
                  name:"mulk-odemeler",
                  meta:{
                    title:"Mülk Ödemeleri | MülkPlus",
                    middleware:[verifyUser]
                  },
                  component:()=>import("@/components/properties/detail/Odemeler.vue")
                },
                {
                  path:"ozellikler",
                  name:"mulk-ozellikler",
                  meta:{
                    title:"Mülk Özellikler | MülkPlus",
                    middleware:[verifyUser]
                  },
                  component:()=>import("@/components/properties/detail/Ozellikler.vue")
                },
                {
                  path:"genelozellikler",
                  name:"mulk-genelozellikler",
                  meta:{
                    title:"Mülk Genel Özellikler | MülkPlus",
                    middleware:[verifyUser]
                  },
                  component:()=>import("@/components/properties/detail/GenelOzellikler.vue")
                },
                {
                  path:"resimler",
                  name:"mulk-resimler",
                  meta:{
                    title:"Mülk Resimler | MülkPlus",
                    middleware:[verifyUser]
                  },
                  component:()=>import("@/components/properties/detail/Resimler.vue")
                },
                {
                  path:"etiketler",
                  name:"mulk-etiketler",
                  meta:{
                    title:"Mülk Etiketler | MülkPlus",
                    middleware:[verifyUser]
                  },
                  component:()=>import("@/components/properties/detail/Etiketler.vue")
                },
                {
                  path:"belgeler",
                  name:"mulk-belgeler",
                  meta:{
                    title:"Mülk Belgeler | MülkPlus",
                    middleware:[verifyUser]
                  },
                  component:()=>import("@/components/properties/detail/Belgeler.vue")
                },
                {
                  path:"gecmis",
                  name:"mulk-gecmis",
                  meta:{
                    title:"Mülk Geçmiş | MülkPlus",
                    middleware:[verifyUser]
                  },
                  component:()=>import("@/components/properties/detail/Gecmis.vue")
                },
              ]
            },
            {
              path: "mulk-ekle",
              name: "mulk-ekle",
              meta: {
                title: "Mülk Ekle | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/view/pages/AddProperty.vue"),
            },
          ]
        },
        {
          path: "/teknik-destek",
          name: "teknik-destek-listesi",
          meta: {
            title: "Teknik Destek | DveB Panel",
            middleware: [verifyUser],
          },
          component: () => import("@/view/layout/SubLayout"),
          children: [
            {
              path: "/",
              name: "teknik-destek-listesi",
              meta: {
                title: "Teknik Destek | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/view/pages/Support.vue"),
            },
            {
              path: "teknik-destek-detay/:id/",
              name: "teknik-destek-detayı",
              meta: {
                title: "Teknik Destek | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/view/pages/SupportDetail.vue"),
            },
          ],
        },
        {
          path: "/odemeler",
          name: "odemeler",
          meta: {
            title: "Ödemeler | DveB Panel",
            middleware: [verifyUser],
          },
          component: () => import("@/view/layout/SubLayout"),
          children: [
            {
              path: "musteri-odemeler",
              name: "musteri-odemeler",
              meta: {
                title: "Müşteri Ödemeleri | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/components/odemeler/MusteriOdeme.vue"),
            },
            {
              path: "sigorta-odemeler",
              name: "sigorta-odemeler",
              meta: {
                title: "Sigorta Ödemeleri | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/components/odemeler/SigortaOdeme.vue"),
            },
            {
              path: "vergi-odemeler",
              name: "vergi-odemeler",
              meta: {
                title: "Vergi Ödemeleri | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/components/odemeler/VergiOdeme.vue"),
            },
            {
              path: "kiraci-odemeler",
              name: "kiraci-odemeler",
              meta: {
                title: "Kiracı Ödemeleri | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/components/odemeler/KiraciOdeme.vue"),
            },
            {
              path: "aidat-odemeler",
              name: "aidat-odemeler",
              meta: {
                title: "Aidat Ödemeleri | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/components/odemeler/AidatOdeme.vue"),
            },
            {
              path: "isortaklari-odemeler",
              name: "isortaklari-odemeler",
              meta: {
                title: "İş Ortakları Ödemeleri | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/components/odemeler/IsOrtaklariOdeme.vue"),
            },
          ],
        },
        {
          path: "/is-ortaklari",
          name: "is-ortaklari-list",
          meta: {
            title: "İş Ortakları | DveB Panel",
            middleware: [verifyUser],
          },
          component: () => import("@/view/layout/SubLayout"),
          children:[
            {
              path: "/",
              name: "is-ortaklari-list",
              meta: {
                title: "İş Ortakları | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/view/pages/Isortaklari.vue"),
            },
            {
              path: "is-ortaklari-ekle",
              name: "is-ortaklari-ekle",
              meta: {
                title: "İş Ortağı Ekle | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/view/pages/Addisortaklari.vue"),
            },
            {
              path: "is-ortaklari-calisanlar",
              name: "is-ortaklari-calisanlar",
              meta: {
                title: "İş Ortağı Çalışanlar | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/view/pages/Calisanisortaklari.vue"),
            },
          ]
        },
        {
          path: "/takip",
          name: "takip",
          meta: {
            title: "Takip | DveB Panel",
            middleware: [verifyUser],
          },
          component: () => import("@/view/layout/SubLayout"),
          children:[
            {
              path: "istakip",
              name: "istakip",
              meta: {
                title: "İş Takibi | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/components/takip/IsTakip.vue"),
            },
            {
              path: "vadetakip",
              name: "vadetakip",
              meta: {
                title: "Vade Takibi | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/components/takip/VadeTakip.vue"),
            },
            {
              path: "slatakip",
              name: "slatakip",
              meta: {
                title: "SLA Takibi| DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/components/takip/SlaTakip.vue"),
            },
          ]
        },
        
        
        
        {
          path: "/musteriler",
          name: "musteriler",
          meta: {
            title: "Müşteriler | DveB Panel",
            middleware: [verifyUser],
          },
          component: () => import("@/view/layout/SubLayout"),
          children:[
            {
              path: "/",
              name: "musteriler",
              meta: {
                title: "Müşteriler | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/view/pages/Customers.vue"),
            },
            {
              path: "musteri-ekle",
              name: "musteri-ekle",
              meta: {
                title: "Müşteri Ekle | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/view/pages/AddCustomer.vue"),
            },
          ]
        },
        
        {
          path: "/talep-ekle",
          name: "talep-ekle",
          meta: {
            title: "Talep Ekle | DveB Panel",
            middleware: [verifyUser],
          },
          component: () => import("@/view/pages/AddRequest.vue"),
        },
        {
          path: "/hatirlatmalar",
          name: "hatirlatmalar",
          meta: {
            title: "Talepler | DveB Panel",
            middleware: [verifyUser],
          },
          component: () => import("@/view/layout/SubLayout"),
          children:[
            {
              path: "/",
              name: "hatirlatmalar",
              meta: {
                title: "Talepler | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/view/pages/Reminders.vue"),
            },
            {
              path: "hatirlatma-ekle",
              name: "hatirlatma-ekle",
              meta: {
                title: "Talep Ekle | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/view/pages/AddReminder.vue"),
            },
          ]
        },
        {
          path: "/paketler",
          name: "paketler",
          meta: {
            title: "Paketler | DveB Panel",
            middleware: [verifyUser],
          },
          component: () => import("@/view/layout/SubLayout"),
          children:[
            {
              path: "paket",
              name: "paket",
              meta: {
                title: "Paketler | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/components/paketler/Paket.vue"),
            },
            
          ]
        },
        {
          path: "/raporlar",
          name: "raporlar",
          meta: {
            title: "Raporlar | DveB Panel",
            middleware: [verifyUser],
          },
          component: () => import("@/view/layout/SubLayout"),
          children:[
            {
              path: "rapor",
              name: "rapor",
              meta: {
                title: "Raporlar | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/components/raporlar/Rapor.vue"),
            },
            
          ]
        },
        {
          path: "/kiracilar",
          name: "kiracilar",
          meta: {
            title: "Kiracılar | DveB Panel",
            middleware: [verifyUser],
          },
          component: () => import("@/view/layout/SubLayout"),
          children:[
            {
              path: "/",
              name: "kiracilar",
              meta: {
                title: "Kiracılar | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/view/pages/Kiracilar.vue"),
            },
            {
              path: "ekle",
              name: "kiraci-ekle",
              meta: {
                title: "Kiracı Ekle | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/view/pages/KiraciEkle.vue"),
            },
            {
              path: "detay/:id/",
              name: "kiraci-detay",
              meta: {
                title: "Kiracı Detay | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/view/pages/KiraciDetay.vue"),
            },
          ]
        },
        {
          path: "/yonetim",
          name: "yonetim",
          meta: {
            title: "Yönetim | DveB Panel",
            middleware: [verifyUser],
          },
          component: () => import("@/view/layout/SubLayout"),
          children:[
            {
              path: "musteriler",
              name: "yonetim/musteriler",
              meta: {
                title: "Müşteri Yönetimi | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/components/manage/Customers.vue"),
            },
            {
              path: "kiracilar",
              name: "yonetim/kiracilar",
              meta: {
                title: "Kiracı Yönetimi | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/components/manage/Kiraci.vue"),
            },
            {
              path: "mulkler",
              name: "yonetim/mulkler",
              meta: {
                title: "Müşteri Yönetimi| DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/components/manage/Properties.vue"),
            },
            {
              path: "dokuman",
              name: "yonetim/dokuman",
              meta: {
                title: "Doküman Yönetimi| DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/components/manage/Dokuman.vue"),
            },
          ]
        },
        {
          path: "/bize-ulasin",
          name: "bize-ulasin",
          meta: {
            title: "Bize Ulaşın | DveB Panel",
            middleware: [verifyUser],
          },
          component: () => import("@/view/pages/ContactUs.vue"),
        },
        {
          path: "/sistem-kullanicilari",
          component: () => import("@/view/pages/systemUsers/Home.vue"),
          children: [
            {
              path: "",
              name: "systemUsers",
              meta: {
                title: "Sistem Kullanıcı Listesi | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/view/pages/systemUsers/List.vue"),
            },
          ],
        },

      ],
    },
    {
      path: "/",
      component: () => import("@/view/layout/Auth"),
      children: [
        {
          path: "/giris",
          name: "login",
          meta: {
            title: "Giriş | DveB Panel",
            middleware: [verifyRoute],
          },
          component: () => import("@/view/pages/auth/Login"),
        },
      ],
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      meta: {
        title: "Sayfa bulunamadı! | DveB Panel",
      },
      component: () => import("@/view/pages/error/Error.vue"),
    },
  ],
});

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      store,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  } else {
    return next();
  }
});
export default router;
